import React, { Component } from 'react';
import axios from 'axios';

// function TowerDetail(props) {
//     return (
//         <div>{props</div>
//     )
// }

const API_URL = process.env.REACT_APP_API_URL;
export default class TowerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    axios
      .get(API_URL + '/reports', {
        headers: {
          Authorization: 'Bearer ' + this.props.user.access_token
        }
      })
      .then(response => {
        this.setState({
          data: response.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    let items = this.state.data.map(report => {
      const avail = report.attributes.availabilities;
      let msg;
      if (avail && avail.length) {
        msg = `Available on: ${avail.join(', ')}`;
      } else {
        msg = 'No Availabilities';
      }
      return <div key={report.id}>{msg}</div>;
    });

    return items;
  }
}
