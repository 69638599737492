import {
  UserManager,
  WebStorageStateStore,
  InMemoryWebStorage
} from 'oidc-client';
import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Box } from 'grommet';

export const settings = {
  authority: process.env.REACT_APP_OIDC_PROVIDER_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_DOMAIN}/auth/signin/standard`,
  silent_redirect_uri: `${process.env.REACT_APP_DOMAIN}/auth/signin/silent`,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  response_type: 'code',
  scope: 'email profile openid subscriptions:read',
  automaticSilentRenew: true,
  post_logout_redirect_uri: process.env.REACT_APP_DOMAIN
};

export const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

const userManager = new UserManager(settings);

function useLoggedInUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    function handleUserChange(user) {
      setUser(user);
      setLoading(false);
    }

    function handleTokenExpiration() {
      userManager.removeUser();
    }

    async function listenForUser() {
      userManager.events.addUserLoaded(handleUserChange);
      userManager.events.addUserUnloaded(handleUserChange);
      userManager.events.addAccessTokenExpired(handleTokenExpiration);
      let u = await userManager.getUser();
      setUser(u);
      if (u) {
        setLoading(false);
      } else {
        try {
          await userManager.signinSilent();
        } catch (ex) {
          setLoading(false);
        }
      }
    }

    function cleanup() {
      userManager.events.removeUserLoaded(handleUserChange);
      userManager.events.removeUserUnloaded(handleUserChange);
      userManager.events.removeAccessTokenExpired(handleTokenExpiration);
    }
    listenForUser();

    return cleanup;
  }, []);
  return { isLoading, user };
}

function SilentSigninCallback(props) {
  userManager.signinSilentCallback();
  return null;
}

function StandardSigninCallback(props) {
  let [error, setError] = useState(null);
  let history = useHistory();
  useEffect(() => {
    async function handleSignIn(history) {
      try {
        await userManager.signinRedirectCallback();
        history.replace('/'); // successful login
      } catch (err) {
        console.warn(err.message);
        setError(err.error);
      }
    }
    handleSignIn(history);
  }, [history]);
  if (error) {
    return <SigninError msg={error}></SigninError>;
  }
  return null;
}

function SigninError(props) {
  return (
    <div>
      <Box flex align="center" justify="center">
        <h2>Unable to Signin</h2>;
        <a href="https://auth.towerscouter.com/signin/logout">Try again</a>
      </Box>
    </div>
  );
}

function LogoutPage({ isLoading, user, userManager }) {
  let history = useHistory();
  let [isLoggingOut, setLoggingOut] = useState(false);
  if (!isLoading && !isLoggingOut) {
    if (user) {
      userManager.signoutRedirect();
      setLoggingOut(true);
    } else {
      history.replace('/');
    }
  }

  return <Loading msg="Logging Out" />;
}

function Loading({ msg }) {
  return (
    <Box flex align="center" justify="center">
      <h2>{msg}</h2>;
    </Box>
  );
}

export function AuthenticationProvider({ children }) {
  const { isLoading, user } = useLoggedInUser();
  let history = useHistory();
  let logout = () => history.replace('/logout');
  let login = () => userManager.signinRedirect();

  return (
    <AuthContext.Provider value={{ user, logout, login }}>
      <Switch>
        <Route path="/auth/signin/silent">
          <SilentSigninCallback userManager={userManager} />
        </Route>

        <Route path="/auth/signin/standard">
          <StandardSigninCallback userManager={userManager} />
        </Route>
        <Route path="/logout">
          <LogoutPage
            isLoading={isLoading}
            user={user}
            userManager={userManager}
          />
        </Route>

        {isLoading ? <Loading msg="Loading" /> : children}
      </Switch>
    </AuthContext.Provider>
  );
}
