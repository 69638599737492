import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Collapsible,
  Heading,
  Grommet,
  Layer,
  ResponsiveContext
} from 'grommet';
import { FormClose, Notification } from 'grommet-icons';
import TowerTable from './TowerTable';
import { AuthenticationProvider, AuthContext } from './oidc';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

const theme = {
  global: {
    colors: {
      brand: '#228BE6'
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px'
    }
  }
};

const AppBar = props => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand"
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation="medium"
    style={{ zIndex: '1' }}
    {...props}
  />
);

const Header = props => (
  <AppBar>
    <Heading level="4" margin="none">
      Tower Scouter
    </Heading>
    <Button icon={<Notification />} onClick={props.onSidebarClick} />
  </AppBar>
);

const Sidebar = props => {
  if (!props.isEnabled || props.size !== 'small') {
    return (
      <Collapsible direction="horizontal" open={props.isEnabled}>
        <Box
          flex
          width="medium"
          background="light-2"
          elevation="small"
          align="center"
          justify="center"
        >
          sidebar
        </Box>
      </Collapsible>
    );
  } else {
    return (
      <Layer>
        <Box
          background="light-2"
          tag="header"
          justify="end"
          align="center"
          direction="row"
        >
          <Button icon={<FormClose />} onClick={props.onClose} />
        </Box>
        <Box fill background="light-2" align="center" justify="center">
          sidebar
        </Box>
      </Layer>
    );
  }
};

function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <Switch>
          <PrivateRoute path="/app">
            <Home />
          </PrivateRoute>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </AuthenticationProvider>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function LoginButton(props) {
  return (
    <button data-testid="login" onClick={props.onClick}>
      Login
    </button>
  );
}

function LogoutButton(props) {
  return (
    <button data-testid="logout" onClick={props.onClick}>
      Logout
    </button>
  );
}

function LoginControl() {
  const { user, logout, login } = useContext(AuthContext);

  let button;

  if (user) {
    button = <LogoutButton onClick={logout} />;
  } else {
    button = (
      <div>
        <LoginButton onClick={login} />
      </div>
    );
  }

  return (
    <div>
      <Greeting user={user} /> {button}
    </div>
  );
}

function UserGreeting(props) {
  return (
    <h1>
      <span>Welcome Back </span>
      <span data-testid="user-fullname">{props.user.profile.name}</span>!
    </h1>
  );
}

function GuestGreeting(props) {
  return <h1> Please sign up. </h1>;
}

function Greeting({ user }) {
  if (user) {
    return (
      <div>
        <UserGreeting user={user} />
        <TowerTable user={user} />
      </div>
    );
  }
  return <GuestGreeting />;
}

function Home() {
  return (
    <Grommet theme={theme} full>
      <Body />
    </Grommet>
  );
}

function Body() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box fill>
          <Header onSidebarClick={() => setShowSidebar(!showSidebar)} />

          <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
            <Box flex align="center" justify="center">
              app body
              <LoginControl />
            </Box>
            <Sidebar
              isEnabled={showSidebar}
              size={size}
              onClose={() => setShowSidebar(false)}
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
export default App;
